@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

::-webkit-scrollbar-track {
  background-color: #333;
}

.css-1uekgls-MuiPaper-root-MuiDrawer-paper {
	background-image: none !important;
}